import React, { Component } from 'react';
import $ from 'jquery';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

export default class ReductionTable extends Component {
	constructor(props) {
    super(props);
        this.state = { itemArray:[] }
        this.shoot = this.shoot.bind(this); 
    }
        
    shoot(event) {
        let name = event.target.name;
		let id = this.props.data.fieldId;
		let preView = this.props.data.preView;
		let value = event.target.value;
		let sReduction = this.state.sReduction
		if(name == 'sReduction'){
			if(value > 25){
				alert('The value should be less than or equal 25%.');
				return false;
			}
			sReduction = value;
			this.setState({sReduction});
		}

		let itemArray = this.state.itemArray
		
		itemArray[name] = value;
		
		this.setState({itemArray});
		
		var rows = [-8,-6,-4,-2,0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16];
		let newItem = [];
		for(let i=1; i <= 21; i++){
			let mVal = itemArray['M'+i];
			let cVal = itemArray['C'+i];
			if(!mVal){
				mVal = $('#'+id+'-M'+i).val();
			}
			if(sReduction && mVal){
				cVal = sReduction;
				$('#'+id+'-C'+i).val(cVal);
			}
			
			if(!mVal){
				$('#'+id+'-C'+i).val('');
			}
			
			if(name == 'sReduction'){
				if(!value){
					$('#'+id+'-C'+i).val('');
				}
			}
			
			let rVal = Number(mVal)-(Number(cVal)/100)*Number(mVal);
			if(rVal){
				$('#'+id+'-R'+i).val(rVal);
				let res = rows[i-1]+','+mVal+','+cVal+','+rVal
				newItem.push(res);
			}else{
				$('#'+id+'-R'+i).val('');
			}
		}
		
		let newItemArray = []
		Object.keys(newItem).map(function(key) {
           newItemArray.push(newItem[key]);
        });
		
		let itemString = newItemArray.join('|');
		this.props.func(this.props.data.fieldId,itemString);
		
    }
	
    render(){
		
        const {data} = this.props;
        let id = data.fieldId;
		let options = data.options.split(',');
		let kioskResponse = data.kioskResponse;
		
		let sReduction = this.state.sReduction
        
        var ts = '';
        if(kioskResponse.size > 0){
            var resAns = kioskResponse.get(id)
            if(resAns !== undefined){
                ts = resAns.split('|');
            }
        }
		var headers = ['Measurement','Reduction %','Goal'];
		var rows = [-8,-6,-4,-2,0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16];
		
		let headerHtml = headers.map(function(headerText,i) {
			return (	
			<TableCell key={i} align="center">
				{headerText}
			</TableCell>
			);
		})
		
		let optionsHtml = rows.map(function(rowText,i) {
			let r = i+1;
			let response = '';
			if(ts){
				ts.map(function(resStr,n) {
					let rowCol = resStr.split(',');
					if(rowCol[0] == rowText){
						response = rowCol;
					}
				})
			}
			
			return (	
				<TableRow key={'a'+i}>
					<TableCell key={'n'+i}>
					{rowText}
					</TableCell>
					{
						headers.map(function(headerText,j) {
							let c = j+1;
							let name = 'M'+r;
							if(c == 2){
								name = 'C'+r;
							}else if(c == 3){
								name = 'R'+r;
							}
							if(response){
								return(
								<TableCell key={'a'+j}>
								{c == 3 ? 
									<TextField value={response[c]} focused={false} type="number" hiddenLabel id="filled-hidden-label-small" label="" name={name} variant="outlined" size="small" disabled="disabled" />
									:
									<TextField value={response[c]} focused={false} type="number" hiddenLabel id="filled-hidden-label-small" label="" name={name} variant="outlined" size="small" />
								}
								</TableCell>
								);
							}else{
								return(
								<TableCell key={'a'+j}>
								{c == 3 ? 
									<TextField focused={false} type="number" hiddenLabel id="filled-hidden-label-small" label="" name={name} variant="outlined" size="small" disabled="disabled" />
									:
									<TextField focused={false} type="number" hiddenLabel id="filled-hidden-label-small" label="" name={name} variant="outlined" size="small" />
								}
								</TableCell>
								);
							}
						})
						
					}
				</TableRow>
			);
		})
		
		return (
			<TableContainer component={Paper} onChange={this.shoot}>
			  <Table sx={{ minWidth: 555 }} size="small" aria-label="a dense table">
				<TableHead>
					<TableRow>
					<TableCell colSpan="2"><span>Standard Reduction %</span> </TableCell>
					<TableCell colSpan="2"><TextField type="number" value={sReduction} hiddenLabel id="filled-hidden-label-small" label="" name='sReduction' variant="outlined" size="small" /></TableCell>
					</TableRow>
					<TableRow>
					<TableCell width="90px">At Level</TableCell>
						{headerHtml}
					</TableRow>
				</TableHead>
				<TableBody>
					{optionsHtml}
				</TableBody>
			  </Table>
			</TableContainer>
		);
    }
}
