import React, { Component } from 'react';
import $ from 'jquery';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import TextField from "@material-ui/core/TextField";

export default class Grid5PointTable extends Component {
	
	constructor(props) {
    super(props);
        this.state = { itemArray:[] }
        this.shoot = this.shoot.bind(this);
    }
	
	shoot(event) {
        let name = event.target.name;
		let id = this.props.data.fieldId;
		let value = event.target.value;
		
		let itemArray = this.state.itemArray;
		itemArray[name] = value;
		this.setState({itemArray});
		//console.log('itemArray->',itemArray);
		this.saveResponse();
    }
	
	saveResponse() {
		let options = this.props.data.labels;
		let rows = [];
		let headers = [];
		if(options){    
			let str = options.split('_');
			headers = str[0].split(',');
			rows = str[1].split(',');
		}
		
		let itemArray = this.state.itemArray;
		
		let resArray = [];
		if(rows){
			rows.map(function(rowText,i) {
				i = i+1;
				let heaArray = [];
				headers.map(function(heaText,n) {
					n = n+1;
					let ids = i+'-'+n;
					let vau = false;
					Object.keys(itemArray).map(function(key) {
						if(key == rowText && itemArray[key] == heaText && itemArray[key] !=''){
							vau = true;
						}
					});
					heaArray.push(vau);
				})
				
				resArray[i-1] = heaArray;
			}) 
		}
		
		//console.log('resArray->',resArray);
		
		this.props.func(this.props.data.fieldId,resArray); 
		
	}
	
	render(){
		const {data} = this.props;
        let id = data.fieldId;
		let options = data.labels;
		let headers = [];
		let rows = [];
		if(options){    
			let str = options.split('_');
			headers = str[0].split(',');
			rows = str[1].split(',');
		}
		let headerHtml = '';
		if(headers){
			headerHtml = headers.map(function(headerText,i) {
				return (	
				<TableCell key={'a'+i} align="center">
					{headerText}
				</TableCell>);
			})
		}
		let optionsHtml = '';
		if(rows){
			optionsHtml = rows.map(function(rowText,i) {
				let r = i+1;
				return (	
					<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell component="th" scope="row">
						{rowText}
						</TableCell>
						{
							headers.map(function(headerText,j) {
								let c = j+1;
								return(	
								<TableCell key={j} align="center">
									<input className="cus-radio-btn" type="radio" value={headerText}  name={rowText}/>
								</TableCell>);
							})
						}
					</TableRow>
				);
			}) 
		}
		
		return (
			<TableContainer component={Paper} onChange={this.shoot}>
			  <Table sx={{ minWidth: 566 }} size="small" aria-label="a dense table">
				<TableHead>
				  <TableRow key={'-1'}>
					<TableCell></TableCell>
					{headerHtml}
				  </TableRow>
				</TableHead>
				<TableBody>
					{optionsHtml}
				</TableBody>
			  </Table>
			</TableContainer>
		);
    }
}