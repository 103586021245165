import React, { Component } from 'react';
import $ from 'jquery';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import {DebounceInput} from 'react-debounce-input';

export default class BasicGridTable extends Component {
	
	constructor(props) {
    super(props);
        this.state = { itemArray:[] }
        this.shoot = this.shoot.bind(this);
		this.handleChange = this.handleChange.bind(this); 
    }
	
	handleChange = (name, date) => {
		var newDate = date.getDate()+'/'+(date.getMonth()+1)+'/'+date.getFullYear();
		this.setState({[name]: date,date});
		let itemArray = this.state.itemArray;
		itemArray[name] = newDate;
		this.setState({itemArray});
		this.saveResponse();
	}
	
	saveResponse() {
		let id = this.props.data.fieldId;
		let options = this.props.data.labels;
		let validation = this.props.data.validation.split(',');
		let rows = [];
		let headers = [];
		if(options){    
			let str = options.split('_');
			headers = str[0].split(',');
			rows = str[1].split(',');
		}
		let that=this;
		let itemArray = this.state.itemArray;
		
		let resArray = [];
		if(rows){
			rows.map(function(rowText,i) {
				i = i+1;
				let heaArray = [];
				headers.map(function(heaText,n) {
					let str = validation[n] ? validation[n].split(':') : '';
					let valiType = str[1];
					let min = str[2] ? str[2] : '';
					let max = str[3] ? str[3] : '';
		
					n = n+1;
					let ids = id+i+'-'+n;
					let vau = '';
					Object.keys(itemArray).map(function(key) {
						if(key == ids && itemArray[key] !=''){
							
							if((valiType == 'V3' || valiType == 'V4' || valiType == 'V5' || valiType == 'V6') && min && max){
								$("input[name='"+ids+"']").removeClass('error');
								if(Number(itemArray[key]) >= Number(min) && Number(itemArray[key]) <= Number(max)){
									vau = itemArray[key];
								}else{
									alert('The value should be greater than or equal to '+min+' and less than or equal to '+max+' value.');
									$("input[name='"+ids+"']").addClass('error');
									$("input[name='"+ids+"']").val('');
									return false;
								}
							}else if((valiType == 'V9' || valiType == 'V11') && min && max  ){
								
								let dateParts = itemArray[key].split("/");
								let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
								
								let mi = min.split("/");
								let ma = max.split("/");
								
								let minDate = new Date(+mi[2], mi[0] - 1, +mi[1]);
								let maxDate = new Date(+ma[2], ma[0] - 1, +ma[1]);
								if(dateObject >= minDate && dateObject <= maxDate){
									vau = itemArray[key];
								}else{
									
									that.setState({[key]:max});
									alert('The Date should be greater than or equal to '+min+' and less than or equal to '+max+' value.');
									//$("input[name='"+ids+"']").addClass('error');
									//$("input[name='"+ids+"']").val('');
									return false;
								}
							
							}else{
								vau = itemArray[key];
							}
						}
					});
					heaArray.push(vau);
				})
				
				resArray[i-1] = heaArray;
				
			}) 
		}
		this.props.func(this.props.data.fieldId,resArray); 
		
	}
	
	shoot(event) {
        let name = event.target.name;
		let id = this.props.data.fieldId;
		let value = event.target.value;
		
		let itemArray = this.state.itemArray;
		itemArray[name] = value;
		this.setState({itemArray});

		this.saveResponse();
    }
	
	render(){
		const {data} = this.props;
        let id = data.fieldId;
		let options = data.labels;
		let validation = data.validation.split(',');
		let headers = [];
		let rows = [];
		if(options){    
			let str = options.split('_');
			headers = str[0].split(',');
			rows = str[1].split(',');
		}
		let headerHtml = '';
		if(headers){
			headerHtml = headers.map(function(headerText,i) {
				return (	
				<TableCell key={'n'+i} align="center">
					{headerText}
				</TableCell>	
				);
			})
		}
		let optionsHtml = '';
		let that = this;
		if(rows){
			optionsHtml = rows.map(function(rowText,i) {
				let r = i+1;
				return (	
					<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell key={'a'+i}component="th" scope="row">
						{rowText}
						</TableCell>
						{
							headers.map(function(headerText,j) {
								let c = j+1;
								let str = validation[j] ? validation[j].split(':') : '';
								let valiType = str[1];
								if(valiType == 'V9' || valiType == 'V10' || valiType == 'V11' || valiType == 'V12'){
									return(
									<TableCell key={j} align="right">
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<Stack spacing={3}>
											<DesktopDatePicker
												label=""
												inputFormat="MM/dd/yyyy"
												value={that.state[id+r+'-'+c] ? that.state[id+r+'-'+c] : str[3]}
												onChange={that.handleChange.bind(this, id+r+'-'+c)}
												renderInput={(params) => <TextField {...params} name={id+r+'-'+c} onChange={that.shoot}/>}
											/>
										</Stack>
									</LocalizationProvider>
									</TableCell>);
								}else{
									return(	
									<TableCell key={j} align="right">
										{/* <TextField focused={false} hiddenLabel id="filled-hidden-label-small" label="" variant="outlined" size="small" name={id+r+'-'+c}/> */}
										<DebounceInput id={id} type="text" debounceTimeout={4000} name={id+r+'-'+c} className={'form-control res_'+id } onChange={that.shoot} />
									</TableCell>);
								}
								
							})
						}
					</TableRow>
				);
			}) 
		}
		
		return (
			<TableContainer component={Paper}>
			  <Table sx={{ minWidth: 566 }} size="small" aria-label="a dense table">
				<TableHead>
				  <TableRow>
					<TableCell></TableCell>
					{headerHtml}
				  </TableRow>
				</TableHead>
				<TableBody>
					{optionsHtml}
				</TableBody>
			  </Table>
			</TableContainer>
		); 
    }
	
}
