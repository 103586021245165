import React, { Fragment, useState } from 'react';
import $ from 'jquery';
import { makeStyles }   from '@material-ui/core/styles';
import TextField        from '@material-ui/core/TextField';
import Button           from '@material-ui/core/Button';
import Grid             from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
//import Snackbar         from '@material-ui/core/Snackbar';
import Box              from '@material-ui/core/Box';
import { Snackbar } from '@material-ui/core';

import AddIcon                from '@material-ui/icons/Add';
import FilterNoneIcon         from '@material-ui/icons/FilterNone';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';

import config from "../../config";
import axios  from 'axios';
import { CopyToClipboard }    from 'react-copy-to-clipboard';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: '10px'
    },
    fileGrid: {
        border: '1px solid #c5c5c5',
        //borderRadius: '5px',
        margin: '10px -10px',
        //paddingBottom: '25px',
        //maxWidth: 345,
    },
    inputFile: {
        marginTop: '20px',
    },
    uploadButton: {
        borderRadius: '20px',
    },
    spinner: {
        marginLeft: '15px'
    }
}));

const Alert = (props) => {
    return '<MuiAlert elevation={6} variant="filled" {...props} />';
}

export default function BasicTextFields(props) {
    
    const classes = useStyles();

    const [title, setTitle]             = useState('');
    const [description, setDescription] = useState('');
    const [fileName, setFileName]       = useState('');
    const [videoFile, setVideoFile]     = useState('');

    const [client, setClient] = useState('');
    const [secret, setSecret] = useState('');
    const [access, setAccess] = useState('');

    const [videoLink, setVideoLink]         = useState('');
    const [uri, setUri]                     = useState('');
    const [embedCode, setEmbedCode]         = useState('');
    const [showSpinner, setShowSpinner]     = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [showUploadButton, setShowUploadButton] = useState(false);

    const [success, setSuccess]         = useState(false);
    const [error, setError]             = useState(false);
    const [warnings, setWarnings]       = useState(false);
    const [information, setInformation] = useState(false);
    
    const [successMessage, setSuccessMessage]         = useState('');
    const [errorMessage, setErrorMessage]             = useState('');
    const [warningMessage, setWarningMessage]         = useState('');
    const [informationMessage, setInformationMessage] = useState('');
	
    const [showEmbedCode, setShowEmbedCode] = useState(false);
    const [showLink, setShowLink]           = useState(false);
	
	
    // Upload video to Vimeo
    const uploadSubmit = () => {
		const ApiUrl = config.mongoApiUrl;
        //const API_URL = config.API_vimeo;
		
        const client = '4b6e6f016f8ac153825004d86951ddfcc7a624e0';
        const secret = 'Xbciby55FX/LgLxobkTj9KgKpsM9yCq03VvlMHU/fnS4uwnXMK2M8zPWQb2KN1BgqQm0WnJVNjhbZKragiARHHaozdGFh/xeM7/unXxM/stX53pN40i2JPMcxera8feZ';
        const access  = 'a0a4f973cb8e8646aca7f4fd46f6e903';
		
		/* const client = 'd68ed94814cb5010038214330694e56114afc4d3';
        const secret = 'KpnjmfKPKRXys8+BqZyfEdVeaL8M3/mwf7fTMRZro83SzTHK8WYT0AywGv13IwbauqIm7v93H3Jwbry82KZBJhAY9BSTV3C3uKOakQHgWRhTr1lRNhfq3bbev8ELyMhz';
        const access  = '4f0c57e37598c7c2e89716e2cff3dabd'; */
		
        if (!videoFile) {
            setWarningMessage('You have to select a video.');
            setWarnings(true);
            return;
        }

        let formData = new FormData();
        
        formData.append('video_file', videoFile);
        formData.append('title', 'Inline Video');
        //formData.append('description', description);
		formData.append('client_id', client);
        formData.append('client_secret', secret);
        formData.append('access_token', access);
        
        setShowSpinner(true);

        setInformationMessage('Request was been sent successfully. You will need to wait for a moment.');
        setInformation(true);

        axios({
            method: 'POST',
            url: ApiUrl+'vimeo-upload',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
			let data = response.data;
			//console.log('data->',data);
            if (data.success) {
				//setUploadSuccess(true);
                let embedCode = data.embed_code;
				
				let iframeElement = $(embedCode);
				let src = iframeElement.attr('src');
				let newIfram = '<iframe src="'+src+'" width="480" height="360" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="Inline Video" allowfullscreen credentialless></iframe>';
				
				props.uploadVideoPath(newIfram, errorMessage);
                
            }else{
                setErrorMessage('Failed.');
                setError(true);
            }
        })
        .catch(error => {
            setShowSpinner(false);
            console.log('error::', error);
            setErrorMessage('Error occurred.');
            setError(true);
        })
    }
    
    const handleChange = (files) => {
        setFileName(files[0].name);
        setVideoFile(files[0]);
    }

    return (
        <Fragment>
            <form className={classes.root} noValidate autoComplete="off">
				{ !uploadSuccess && <Grid container spacing={3} className={classes.fileGrid}>
                    <Grid item xs={12} className={classes.inputFile}>
                        <Button
                            variant="contained"
                            component="label"
                            color="secondary"
                            className={classes.uploadButton}
                            >
                            <AddIcon /> Select Video File
                            <input
                                type="file"
                                name="video_file" 
                                style={{ display: "none" }}
                                onChange={ (e) => handleChange(e.target.files) }
                            />
                        </Button>
                        { fileName && (<Box component="span" display="block">{fileName}</Box>) }
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={() =>uploadSubmit()}>Upload { showSpinner && <CircularProgress disableShrink size="20px" className={classes.spinner} /> }</Button>
                    </Grid>
                </Grid> }
            </form>
        </Fragment>
    );
}
