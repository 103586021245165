import React, { Fragment, useState } from 'react';
import $ from 'jquery';
import { makeStyles }   from '@material-ui/core/styles';
import Button           from '@material-ui/core/Button';
import Grid             from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon                from '@material-ui/icons/Add';
import Box              from '@material-ui/core/Box';
import config from "../../config";
import axios  from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: '10px'
    },
    fileGrid: {
       // border: '1px solid #c5c5c5',
        //borderRadius: '5px',
        margin: '10px -10px',
        //paddingBottom: '25px',
        //maxWidth: 345,
    },
    inputFile: {
        marginBottom: '10px',
    },
    uploadButton: {
        borderRadius: '20px',
    },
    spinner: {
        marginLeft: '15px'
    }
}));

export default function BasicUploadVideo(props) {
    
    const classes = useStyles();

    const [name, setName]             = useState('');
    const [description, setDescription] = useState('');
    const [tag, setTag] = useState('');
    const [fileName, setFileName]       = useState('');
    const [videoFile, setVideoFile]     = useState('');


    const [embedCode, setEmbedCode]         = useState('');
    const [showSpinner, setShowSpinner]     = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [showUploadButton, setShowUploadButton] = useState(false);

    const [success, setSuccess]         = useState(false);
    const [error, setError]             = useState(false);
    const [warnings, setWarnings]       = useState(false);
    const [information, setInformation] = useState(false);
    
    const [successMessage, setSuccessMessage]         = useState('');
    const [errorMessage, setErrorMessage]             = useState('');
    const [warningMessage, setWarningMessage]         = useState('');
    const [informationMessage, setInformationMessage] = useState('');
	const allVideoTags = props.allVideoTags;
    // Upload video to Vimeo
    const uploadSubmit = () => {
		const ApiUrl = config.VideoApiUrl;
		const VideoCategory = config.VideoCategory;
		const VideoTheme = config.VideoTheme;
        const apiKey = config.VideoApiKey
       
        if (!videoFile) {
            setWarningMessage('You have to select a video.');
            setWarnings(true);
            return;
        }
		
		let tags = [];
		allVideoTags.map((tag, i) => {
			if($("input[name='"+tag._id+"']").prop("checked") == true){
				tags.push(tag._id);
			}
		})
		
		tags = tags.join();

        let formData = new FormData();
        //let tags = "66eff22b949e579cc24b8844";
        formData.append('category', VideoCategory);
        formData.append('theme', VideoTheme);
        formData.append('files', videoFile);
        formData.append('name', name);
        formData.append('description', description);
        //formData.append('tag', "OPIE Forms");
		formData.append('tags', tags);
        
        setShowSpinner(true);

        setInformationMessage('Request was been sent successfully. You will need to wait for a moment.');
        setInformation(true);

        axios({
            method: 'POST',
            url: ApiUrl,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
				'x-api-key': apiKey
            }
        })
        .then(response => {
			let data = response.data;
			if (data.success) {
				setTimeout(function(){
					setUploadSuccess(true);
					setSuccessMessage(data.message);
					setShowSpinner(false);
				}, 5000);
				
                setTimeout(function(){
					props.getVideoFromApi();
					$('#uploadflyingVideo').modal('hide');
				}, 8000);
				
            }else{
                setErrorMessage('Failed.');
                setError(true);
            }
        })
        .catch(error => {
            setShowSpinner(false);
            console.log('error::', error);
            setErrorMessage('Error occurred.');
            setError(true);
        })
    }
    
    const handleChange = (files) => {
        setFileName(files[0].name);
        setVideoFile(files[0]);
    }
	
	const handleName = (name) => {
        setName(name);
    }
	const handleDes = (name) => {
        setDescription(name);
    }
	const handleTag = (name) => {
        setTag(name);
    }
	
	
    return (
        <Fragment>
            <form className={classes.root} noValidate autoComplete="off">
				<Grid container className={classes.fileGrid}>

					<Grid item xs={12} className={classes.inputFile}>
						<label>Name</label>
						<input type="text" className="form-control" name="video_name" onChange={ (e) => handleName(e.target.value) } />
					</Grid>
					
					<Grid item xs={12} className={classes.inputFile}>
						<label>Description</label>
						<input type="text" className="form-control" name="description" onChange={ (e) => handleDes(e.target.value) } />
					</Grid>
					
					<Grid item xs={12} className={classes.inputFile}>
						<h6 className="modal-title mb-2">Tags</h6>
						{allVideoTags.map((val, i) => (
							<div className="v-tag"><input name={val._id} type="checkbox"/> {val.name}</div>
						))}
						
						{/* <input type="text" className="form-control" name="tag" onChange={ (e) => handleTag(e.target.value) } value="OPIE Forms" /> */}
						
					</Grid>
					<Grid item xs={12} className={classes.inputFile}>
						<Button
                            variant="contained"
                            component="label"
                            color="secondary"
                            className={classes.uploadButton}
                            >
                            <AddIcon /> Select Video File
                            <input
                                type="file"
                                name="video_file" 
                                style={{ display: "none" }}
                                onChange={ (e) => handleChange(e.target.files) }
                            />
                        </Button>
                        { fileName && (<Box component="span" display="block">{fileName}</Box>) }
                    </Grid>
                    
                    <Grid item xs={12}>
					
                        <Button variant="contained" onClick={() =>uploadSubmit()}>Upload { showSpinner && <CircularProgress disableShrink size="20px" className={classes.spinner} /> }</Button>
						
						{ successMessage && (<div className="msg-success">{successMessage}</div>)}
						
                    </Grid>
                </Grid>
            </form>
        </Fragment>
    );
}
